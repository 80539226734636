<template>
  <div class="shop-wrapper">
    <div class="shop_banner">
      <img :src="detail.sub_head_img" alt="">
      <span @click="onClickPageLink(detail.gps_address)"></span>
    </div>
    <div class="shop_list">
      <div class="_item" v-for="item of sub_merchant_list" @click="jumpToUrl(item)">
        <img :src="item.sub_img">
      </div>
    </div>
    <div class="_bottom_fixed">
      <div class="_height"></div>
      <div class="_bottom_back">
        <span class="_back_home" @click="$router.push('/home?type='+detail.card_id)"></span>
        <span class="_back_prev" @click="$router.go(-1)"></span>
      </div>
    </div>
    <div class="_right_bar_new_card"
         @click="onClickPageLink('https://wx.scbank.cn/ares-web-wechat/wechat/authinit/applyDebitCard.do')">
      <img src="http://pic.eaworks.cn/scbank/uploads/2023922/6294112.png" alt="">
    </div>
    <TipsWindow ref="tipsWindow1" :type="1" @on-tips-cancel="tipsBackCancel" @on-tips-agree="tipsBackAgree"/>
  </div>
</template>

<script>
import page_links from "../mixin/page_links";
import wx from "weixin-js-sdk";
import TipsWindow from "@/view/components/TipsWindow.vue";

export default {
  mixins: [page_links],
  components: {TipsWindow},
  data() {
    return {
      detail: {},
      sub_merchant_list: [],
      tipsUrl: ""
    };
  },
  mounted() {
    this.get_sub_merchant_list();
    this.getJsInfo();
  },
  methods: {
    // 关闭-Tips弹窗
    tipsBackCancel() {

    },
    // 同意-Tips弹窗
    tipsBackAgree(type) {
      this.onClickPageLink(this.tipsUrl, true);
    },
    jumpToUrl(item) {
      if (item.jump_url == "") {
        return false;
      } else if (item.jump_url.substring(0, 1) == "/") {
        this.$router.push(item.jump_url);
      } else {
        this.$router.push('/shop_detail?merchant_id=' + this.detail.id + '&sub_id=' + item.id);
      }
    },
    async getJsInfo() {
      // 生成网页js签名
      let {data, code} = await this.$ajax.gateway("/api/admin_get_js_sign", {
        "body": {
          "url": location.href.split('#')[0]
        }
      });
      if (code === 200) {
        data.res["jsApiList"] = [
          'checkJsApi',
          'updateTimelineShareData',
          'updateAppMessageShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem'
        ];
        wx.config(data.res);
      }
      wx.ready(function () {
        wx.updateTimelineShareData({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
        wx.onMenuShareTimeline({
          title: '四川银行-安逸生活 从此开启', // 分享标题
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 用户点击了分享后执行的回调函数
          }
        });
        wx.updateAppMessageShareData({
          title: '四川银行', // 分享标题
          desc: '安逸生活 从此开启', // 分享描述
          link: 'http://scb.eaworks.cn/page/home', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
          imgUrl: 'http://pic.eaworks.cn/scbank/uploads/202284/1078189.png', // 分享图标
          success: function () {
            // 设置成功
          }
        });
      });

      wx.error(function (res) {
        console.info("wx error", res);
      });
    },
    async get_sub_merchant_list() {
      // 获取子商户列表
      let {data} = await this.$ajax.gateway("/api/admin_get_sub_merchant_list", {
        "METHOD": "get",
        "merchant_id": this.$route.query.id,
      });
      this.sub_merchant_list = data.list;
      this.detail = data.detail;
      console.info(data);
    }
  }
};
</script>

<style lang="less" scoped>
.shop-wrapper {
  background: #f6f6f6;

  .shop_banner {
    position: relative;

    img {
      display: block;
      width: 100%;
    }

    span {
      display: inline-block;
      width: 3rem;
      height: 1rem;
      position: absolute;
      top: 4.45rem;
      right: 1rem;
    }
  }

  .shop_list {
    padding: 0.3rem 0.7rem;
    min-height: 10rem;

    ._item {
      padding: 0 0;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  ._right_bar_new_card {
    position: fixed;
    top: 0.3rem;
    right: 0rem;

    img {
      height: 2.7rem;
    }
  }
}

</style>
