<template>
  <div class="sx-tips-bg" v-if="tipsReveal">
    <div class="sx-tips-content">
      <div class="sub-tips-icons" v-if="type === 1"><img src="../../status/img/tips_icon_1.png?t=1"></div>
      <div class="sub-tips-icons" v-if="type === 2"><img src="../../status/img/tips_icon_2.png"></div>
      <div class="sub-tips-title">
        <div v-if="type === 1">温馨提示</div>
        <div v-if="type === 2">免责提示</div>
      </div>
      <div class="sub-tips-content">
        <template v-if="type === 1">
          <p>我们即将获取您的定位,用于您查询商户的具体位置</p>
        </template>
        <template v-if="type === 2">
          <p>您即将跳转至第三方提供的服务页面。在第三方服务页面中提交的信息将由第三方按照其隐私政策执行并负责，服务及责任均由第三方提供或承担。</p>
          <p class="_tips_img"><img src="../../status/img/tips_p_1.png"></p>
        </template>
      </div>
      <div class="sub-tips-footer">
        <span @click="onClickCancel">取消</span>
        <span :class="type === 1 ? 'span_1' : 'span_2'" @click="onClickAgree">同意</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mixins: [],
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tipsReveal: false,
    };
  },

  created() {
  },

  mounted() {
  },

  methods: {
    showTips() {
      this.tipsReveal = true;
    },

    onClickCancel() {
      this.tipsReveal = false;
      this.$emit("on-tips-cancel");
    },

    onClickAgree() {
      this.tipsReveal = false;
      this.$emit("on-tips-agree", this.type);
    }
  }
};
</script>

<style lang="less" scoped>
.sx-tips-bg {
  background-color: rgba(0, 0, 0, 0.8);
  width: 8rem;
  padding: 1rem;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  .sx-tips-content {
    width: 8rem;
    min-height: 4rem;
    background: #fff;
    border-radius: 0.2rem;
    margin: 3.5rem auto 0;
    position: relative;
    z-index: 110;
    .sub-tips-icons {
      position: absolute;
      right: 0.6rem;
      top: -1.05rem;
      width: 2rem;
      background: #ffffff;
      border-radius: 100%;
      box-shadow: 0 6px 12px 0 rgba(30, 38, 51, 0.12);
      padding: 0.05rem;
      img {
        display: block;
        width: 100%;
      }
    }
    .sub-tips-title {
      padding: 0.6rem 0.3rem 0;
      border-radius: 0.2rem 0.2rem 0 0;
      background: url("../../status/img/tips_bg.png") no-repeat top left;
      background-size: 100% auto;
      text-align: left;
      font-size: 0.42rem;
      font-weight: 800;
    }
    .sub-tips-content {
      font-size: 0.34rem;
      line-height: 1.6em;
      text-align: left;
      color: #555;
      padding-top: 0.3rem;
      p {
        padding: 0 0.3rem 0.3rem;
        margin: 0;
      }
      p._tips_img {
        padding: 0;
        img {
          display: block;
          width: 100%;
        }
      }
    }
    .sub-tips-footer {
      margin-top: 0.2rem;
      border-top: 1px solid #eee;
      padding: 0 0 0.25rem;
      span {
        display: inline-block;
        width: 49%;
        text-align: center;
        font-size: 0.4rem;
        color: #888;
        line-height: 1rem;
        &:first-child {
          border-right: 1px solid #eee;
        }
        &.span_1 {
          color: #f15d61;
        }
        &.span_2 {
          color: #2c94d0;
        }
      }
    }
  }
}
</style>
